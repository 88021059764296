import React from 'react';
import '../../../i18n';

import { AppContainer } from '../../../components/Common/styled/AppContainer';
import GlobalStyle from '../../../themes/GlobalStyle';
import GlobalThemeStyles from '../../../themes/GlobalThemeStyles';
import { EmptyPageModern } from '../../../components/Common/styled/EmptyPage';
import { AiOneClickRedirect } from '../../../components/AiLink/OneClick';

export default ({ advancedToolId, studyInstanceUid }) => (
  <AppContainer>
    <GlobalStyle />
    <GlobalThemeStyles />
    <EmptyPageModern big>
      <AiOneClickRedirect advancedToolId={advancedToolId} studyInstanceUid={studyInstanceUid} />
    </EmptyPageModern>
  </AppContainer>
);
