import React, { useState } from 'react';

import { AlertUI } from '../../componentsUI/Alert';
import Loading from '../Common/Loading';

export const AiOneClickRedirect = ({ advancedToolId, studyInstanceUid }) => {
  const [progress, setProgress] = useState(null);
  const [result, setResult] = useState({});

  const requestAdvancedTool = () => {
    setProgress('Retrieving Advanced Tool configuration');
    fetch(`${process.env.GATSBY_CLOUD_API_BASE_URL}/ai-link/object/${advancedToolId}`)
      .then((response) => {
        if (!response.ok) {
          response.json().then((string) => {
            const { error } = JSON.parse(string);
            console.warn(error);
            setResult({
              status: 'error',
              title: 'Error',
              message: error,
            });
          });
          return;
        }

        response.json().then((string) => {
          setProgress('Opening advanced tool, please wait...');
          const { AdvancedTool } = JSON.parse(string);

          switch (AdvancedTool.aiType) {
            case 2:
            case 1:
              window.location.href = `${AdvancedTool.location}${studyInstanceUid}`;
              return;
            case 0:
            case 3:
            default:
              setResult({
                status: 'info',
                title: 'Advanced Tool info',
                message: 'The Advanced Tool has been requested successfully. You can now close this window.',
              });
          }
        });
      })
      .catch((e) => {
        console.error(e);
        setResult({
          status: 'error',
          title: 'Error',
          message: 'There has been an error in the Advanced Tool request',
        });
      });
  };

  if (!progress) requestAdvancedTool();

  return (
    (result && result.title && result.status) ? (
      <AlertUI title={result.title} severity={result.status}>
        {result.message}
      </AlertUI>
    ) : (
      <Loading message={progress} />
    )
  );
};
